import { OnlyPublicProperties, Uuid } from "@/types";
import { gettext } from "@/services/translation";
import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { AppRouteName } from "./app";
import { createUrlParams, PublicCollectionItemsFilter } from "@/services/repositories/publicCollectionItems";
import urlSlug from "url-slug";
import { Categories, Category, CategoryNotFoundException } from "@/services/repositories/collection/categories";
import { CollectorsFilter } from "@/services/repositories/collectors";
import { createLocalizedRoute } from "@/router/helpers";
import { factoryLandingPageSetupBoardGames, factoryLandingPageSetupHomepage, factoryLandingPageSetupLegoSets, factoryLandingPageSetupVinyls } from "@/services/landingPages";
import { Layout } from "@/router/routeMeta";
import { saveLastCategoryId } from "@/services/aiHelpers";
import { createError404Link } from "@/router/routes/error";
import { CatalogueItem, CatalogueSearchItem } from "@/services/repositories/database";

export enum PublicRouteName {
  AiPublicForm = "aiFormPublic",
  AiPublicDetail = "aiItemDetailPublic",
  Catalogue = "catalogueStart",
  CatalogueItem = "catalogueItem",
  CatalogueCategory = "catalogueCategory",
  profile = "publicProfile",
  publicHomepage = "homepage",
  publicItemDetail = "publicItemCard",
  about = "about",
  gdpr = "gdpr",
  tac = "tac",
  guide = "guide",
  magazine = "articlesList",
  article = "article",
  contacts = "contacts",
  marketplace = "marketplace",
  collectors = "collectors",
}

export const publicRoutes: RouteRecordRaw[] = [
  /**
   * Landing pages:
   */

  {
    name: PublicRouteName.publicHomepage,
    path: "",
    components: { mainContent: () => import("@/views/public/LandingPage.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Objevuj svět sběratelů"),
      layout: Layout.Public,
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
      landingPageDataFactory: factoryLandingPageSetupHomepage,
    },
  },
  {
    path: ":landingPageCategory(lego-sets|vinyl-records|board-games)",
    components: { mainContent: () => import("@/views/public/LandingPage.vue") },
    meta: {
      public: true,
      layout: Layout.Public,
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
      landingPageDataFactory: factoryLandingPageSetupLegoSets,
    },
    beforeEnter: (to, _, next) => {
      let title;
      let dataFactory;
      const category = to.params.landingPageCategory;
      if (category === "lego-sets") {
        title = gettext.$gettext("Lego®");
        dataFactory = factoryLandingPageSetupLegoSets;
      } else if (category === "vinyl-records") {
        title = gettext.$gettext("Vinyly");
        dataFactory = factoryLandingPageSetupVinyls;
      } else if (category === "board-games") {
        title = gettext.$gettext("Deskové hry");
        dataFactory = factoryLandingPageSetupBoardGames;
      } else {
        throw new Error("DEV: Should not happen.");
      }
      to.meta.title = () => title;
      to.meta.landingPageDataFactory = dataFactory;
      next();
    },
  },
  {
    path: "watches",
    components: { mainContent: () => import("@/views/public/LandingPageWatch.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Hodinky"),
      layout: Layout.Public,
    },
  },
  {
    name: PublicRouteName.gdpr,
    path: "privacy-policy",
    components: { mainContent: () => import("@/views/Gdpr.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Zásady ochrany osobních údajů"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },
  {
    name: PublicRouteName.tac,
    path: "terms-and-conditions",
    components: { mainContent: () => import("@/views/TermsAndConditions.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Všeobecné obchodní podmínky"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },
  {
    name: PublicRouteName.guide,
    path: "guide",
    components: { mainContent: () => import("@/views/Guide.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Právní informace"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },
  {
    name: PublicRouteName.contacts,
    path: "contacts",
    components: { mainContent: () => import("@/views/ContactPage.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Kontakty"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },
  {
    name: PublicRouteName.about,
    path: "about-us",
    components: { mainContent: () => import("@/views/AboutUs.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("O nás"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },

  /**
   * Magazine
   */
  {
    path: "magazine",
    children: [
      {
        name: PublicRouteName.article,
        path: ":id(\\d+)-:url",
        components: { mainContent: () => import("@/views/ArticleDetail.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Magazín"),
          layout: Layout.Public,
          layoutLoggedIn: Layout.App,
        },
      },
      {
        name: PublicRouteName.magazine,
        path: ":category?",
        components: { mainContent: () => import("@/views/ArticlesList.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Magazín"),
          layout: Layout.Public,
          layoutLoggedIn: Layout.App,
          breadcrumbs: [{ text: () => gettext.$gettext("Magazín") }],
        },
      },
    ],
  },

  /**
   * Profiles
   */
  {
    name: PublicRouteName.publicItemDetail,
    path: "collector/:nickname/:slug/:itemId",
    components: { mainContent: () => import("@/views/public/PublicItemDetail.vue") },
    meta: {
      public: true,
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },
  {
    name: PublicRouteName.profile,
    path: "collector/:nickname",
    components: { mainContent: () => import("@/views/public/ProfilePublic.vue") },
    meta: {
      public: true,
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
    },
  },

  /**
   * Public items listing
   */
  {
    name: PublicRouteName.marketplace,
    path: "marketplace",
    components: { mainContent: () => import("@/views/Marketplace.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Tržiště"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
      breadcrumbs: [{ text: () => gettext.$gettext("Tržiště") }],
    },
  },

  /**
   * Collectors list
   */
  {
    name: PublicRouteName.collectors,
    path: "collectors",
    components: { mainContent: () => import("@/views/Collectors.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Sběratelé"),
      layout: Layout.Public,
      layoutLoggedIn: Layout.App,
      breadcrumbs: [{ text: () => gettext.$gettext("Sběratelé") }],
    },
  },

  /**
   * AI vision
   */
  {
    path: "ai-identification",
    children: [
      {
        path: "",
        name: PublicRouteName.AiPublicForm,
        components: { mainContent: () => import("@/views/public/Ai.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("AI identifikace"),
          layout: Layout.Public,
          layoutLoggedIn: Layout.App,
        },
      },
      {
        path: "history/:itemId",
        name: PublicRouteName.AiPublicDetail,
        components: { mainContent: () => import("@/views/IdentificationDetail.vue") },
        meta: {
          public: true,
          layout: Layout.Public,
        },
      },
      {
        path: "category/:seoSlug",
        beforeEnter: async (to, from, next) => {
          try {
            const slug = String(to.params.seoSlug);
            const category = await Categories.getByUrl(slug);
            await saveLastCategoryId(category.getId());
            return next(createAiPublicLookupFormLink());
          } catch (e: unknown) {
            if (e instanceof CategoryNotFoundException) {
              return next(createError404Link());
            } else {
              throw e;
            }
          }
        },
        meta: {
          public: true,
          layout: Layout.Public,
        },
        components: { mainContent: () => import("@/views/app/ai/Form") }, // Should not be ever used (beforeEnter always redirects)
      },
    ],
  },

  /**
   * Catalogue
   */

  {
    path: "database",
    children: [
      {
        path: "",
        name: PublicRouteName.Catalogue,
        components: { mainContent: () => import("@/views/public/catalogue/Intro.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Databáze"),
          breadcrumbs: [{ text: () => gettext.$gettext("Databáze") }],
          layout: Layout.Public,
          layoutLoggedIn: Layout.App,
        },
      },
      {
        name: PublicRouteName.CatalogueCategory,
        path: ":categoryUrl",
        components: { mainContent: () => import("@/views/public/catalogue/Category.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Databáze"),
          breadcrumbs: [{ text: () => gettext.$gettext("Databáze"), to: { name: PublicRouteName.Catalogue } }],
          layout: Layout.Public,
          layoutLoggedIn: Layout.App,
        },
      },
      {
        name: PublicRouteName.CatalogueItem,
        path: ":categoryUrl/:itemId(\\d+)/:nameSeoSlug?",
        components: { mainContent: () => import("@/views/public/catalogue/ItemDetail.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Detail předmětu"),
          breadcrumbs: [{ text: () => gettext.$gettext("Databáze"), to: { name: PublicRouteName.Catalogue } }],
          layout: Layout.Public,
          layoutLoggedIn: Layout.App,
        },
      },
    ],
  },
];

export const createProfileLink = (nickname: string, filter?: OnlyPublicProperties<PublicCollectionItemsFilter>): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.profile,
    params: {
      nickname: nickname,
    },
    query: filter ? createUrlParams(filter) : undefined,
  });

export const createHomepageLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.publicHomepage });

export const createPublicItemDetailLink = (itemId: number, itemName: string, collectorNickname: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.publicItemDetail,
    params: {
      nickname: urlSlug(collectorNickname),
      itemId: itemId,
      slug: urlSlug(itemName) || "-",
    },
  });

export const createAboutLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.about });

export const createGdprLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.gdpr });
export const createTacLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.tac });
export const createGuideLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.guide });

export const createArticleLink = (id: number, url: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.article,
    params: { id, url },
  });

export const createMagazineLink = (category?: Category): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.magazine,
    params: {
      category: category ? category.url : undefined,
    },
  });

export const createContactsLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.contacts });

export const createPublicItemsListLink = (filter?: OnlyPublicProperties<PublicCollectionItemsFilter>): RouteLocationRaw => {
  if (filter) {
    return createLocalizedRoute({
      name: PublicRouteName.marketplace,
      query: createUrlParams(filter),
    });
  } else {
    return createLocalizedRoute({ name: PublicRouteName.marketplace });
  }
};

export const createCollectorsLink = (filter?: CollectorsFilter.Filter): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.collectors,
    query: filter?.createUrlParams(),
  });

export const createAiPublicLookupFormLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.AiPublicForm });
export const createAiPublicItemDetailLink = (itemId: Uuid): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.AiPublicDetail,
    params: { itemId },
  });

/**
 * Catalogue link factories ⤵️
 */

export const createCatalogueLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.Catalogue });
export const createCatalogueItemLink = (category: Category, item: CatalogueItem | CatalogueSearchItem): RouteLocationRaw => {
  const categoryUrl = category.getUrl();
  const itemId = item.getId();
  const nameSeoSlug = urlSlug(item.getName());
  return createLocalizedRoute({
    name: PublicRouteName.CatalogueItem,
    params: { categoryUrl, itemId, nameSeoSlug },
  });
};
export const createCatalogueCategoryLink = (category: Category): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.CatalogueCategory,
    params: {
      categoryUrl: category.getUrl(),
    },
  });
